'use client';

import { usePathname } from '@/i18n/routing';
import { CloseIcon, ErrorIcon, MessengerIcon, SuccessIcon, WarningIcon } from '@/icons';
import { useTouchAvailable } from '@/lib/hooks';
import { AUTHORITY_CODE, NAVBAR_Z_INDEX, sanitizeInput } from '@/lib/utils';
import { Affix, Box, Button, Center, Flex, Modal, Textarea, Transition } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure, useHotkeys, useMediaQuery, useTextSelection } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import { clsx } from 'clsx';
import { useLocale, useTranslations } from 'next-intl';
import { isMobile } from 'react-device-detect';
import classnames from './messenger.module.css';

type FormValues = {
  error_text: string;
  correct_text: string;
};

type MobileHandlerProps = {
  open: () => void;
  selection: string;
};

const closeButtonProps = {
  bg: 'transparent',
  icon: <CloseIcon />,
};

export function Messenger() {
  const t = useTranslations();
  const locale = useLocale();
  const pathname = usePathname();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTouchAvailable = useTouchAvailable();
  const [opened, { open, close }] = useDisclosure(false);
  const [infoOpened, { open: openInfo, close: closeInfo }] = useDisclosure(false);
  const selection = useTextSelection()?.toString()?.trim() || '';

  const { onSubmit, getInputProps, setFieldValue, errors, reset } = useForm<FormValues>({
    initialValues: {
      error_text: '',
      correct_text: '',
    },
    validate: {
      correct_text: (value) => (value?.trim() ? null : t('Bu katakcha to‘ldirilishi shart')),
    },
  });

  function handleOpen() {
    if (!selection || selection?.length === 0) return;

    if (selection?.length < 3 || selection?.length > 35) {
      notifications.show({
        message: t('xatolik-to-g-risida-xabar-yuborish-uchun-3-tadan-35-tagacha-belgidan-iborat-matnni-belgilang'),
        bg: '#fff3cd',
        icon: <WarningIcon />,
        classNames: {
          root: classnames.notification,
          icon: classnames.notification__icon,
          closeButton: classnames.notification__close,
        },
        closeButtonProps,
      });

      return;
    }

    setFieldValue('error_text', selection);
    open();
  }

  const handleSubmit = async (formValues: FormValues) => {
    const params = {
      error_text: sanitizeInput(formValues?.error_text),
      correct_text: sanitizeInput(formValues?.correct_text),
      url: pathname,
    };

    try {
      const {
        data: { status },
      } = await axios.post(
        '/authorities/main/checking-text',
        {},
        { headers: { language: locale, code: AUTHORITY_CODE }, params }
      );

      if (status !== 'OK') {
        close();
        reset();
        notifications.show({
          message: t('diqqat-so-rov-yuborishda-xatolik-yuz-berdi-iltimos-keyinroq-qayta-urinib-ko-ring'),
          bg: 'red',
          icon: <ErrorIcon />,
          classNames: {
            root: classnames.notification,
            description: classnames.notification__description,
            icon: clsx(classnames.notification__icon, classnames.light),
            closeButton: clsx(classnames.notification__close, classnames.light),
          },
          closeButtonProps: {
            bg: 'transparent',
            icon: <CloseIcon />,
          },
        });
        return;
      }
      close();
      reset();
      notifications.show({
        message: t('rahmat-sizning-murojaatingiz-qayta-ishlashga-yuborildi'),
        bg: 'green',
        icon: <SuccessIcon />,
        classNames: {
          root: classnames.notification,
          description: classnames.notification__description,
          icon: clsx(classnames.notification__icon, classnames.light),
          closeButton: clsx(classnames.notification__close, classnames.light),
        },
        closeButtonProps,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useHotkeys([['mod+Enter', handleOpen]]);

  return (
    <>
      <Modal.Root opened={opened} onClose={close} zIndex={NAVBAR_Z_INDEX + 2} size="lg" fullScreen={isMobile}>
        <Modal.Overlay />
        <Modal.Content c="#575757">
          <Modal.Header className={classnames.header}>
            <Modal.Title className="font--md">
              {t('Xabar yuboruvchi')}{' '}
              <button className={classnames.question__mark} onClick={openInfo} aria-label={t('Xabar yuboruvchi')}>
                ?
              </button>
            </Modal.Title>
            <Modal.CloseButton
              // aria-label={t('{text}ni yopish', { text: t('Xabar yuboruvchi') })}
              aria-label={t('Yopish')}
            />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={onSubmit(handleSubmit)} autoComplete="off">
              <Flex direction="column" gap="lg" pt="sm" pb="md">
                <Textarea
                  {...getInputProps('error_text')}
                  label={t('Xatolik aniqlangan matn')}
                  placeholder={t('Xatolik aniqlangan matn')}
                  rows={3}
                  size="md"
                  labelProps={{ fz: 'sm', fw: 400, mb: 5 }}
                  classNames={{ input: classnames.input }}
                  disabled
                />
                <Textarea
                  {...getInputProps('correct_text')}
                  label={t('Iltimos, to‘g‘ri matnni kiriting')}
                  placeholder={t('Iltimos, to‘g‘ri matnni kiriting')}
                  error={errors?.correct_text}
                  errorProps={{ fz: 'xs' }}
                  rows={4}
                  size="md"
                  labelProps={{ fz: 'sm', fw: 400, mb: 5 }}
                  classNames={{ input: classnames.input }}
                  withAsterisk
                />
              </Flex>
              <Flex className={classnames.footer} justify="flex-end" align="center" pt="md" gap="md">
                <Button type="reset" bg="secondary" onClick={close}>
                  {t('Bekor qilish')}
                </Button>
                <Button type="submit" variant="filled" bg="primary">
                  {t('Yuborish')}
                </Button>
              </Flex>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <Modal.Root opened={infoOpened} onClose={closeInfo} size={500} zIndex={NAVBAR_Z_INDEX + 2}>
        <Modal.Overlay backgroundOpacity={0.3} />
        <Modal.Content>
          <Modal.Header className="border-b-[1px] border-zinc-200">
            <Modal.Title className="text-xl text-neutral-500 font-medium">{t('Xabar yuboruvchi')}</Modal.Title>
            <Modal.CloseButton
              icon={<CloseIcon />}
              className={classnames.drawer__close}
              // aria-label={t('{text}ni yopish', { text: `"${t('Xabar yuboruvchi')}"` })}
              aria-label={t('Yopish')}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="pt-5 text-sm text-neutral-800">
              {isTouchAvailable
                ? t(
                    'Diqqat! Agar siz matnda xatoliklarni aniqlasangiz, ma’muriyatni xabardor qilish uchun ularni belgilab, pastda hosil bo‘ladigan tugmani bosing'
                  )
                : t(
                    "Diqqat! Agar siz matnda xatoliklarni aniqlasangiz, ularni belgilab, ma'muriyatni xabardor qilish uchun Ctrl+Enter tugmalarini bosing"
                  )?.replace('Ctrl', 'Ctrl/Command')}
            </p>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      {isTouchAvailable ? <MobileHandler open={handleOpen} selection={selection} /> : null}
    </>
  );
}

function MobileHandler({ open, selection }: MobileHandlerProps) {
  return (
    <Affix position={{ bottom: isMobile ? 10 : 55, left: '50%' }} zIndex={NAVBAR_Z_INDEX + 1}>
      <Transition transition="slide-up" mounted={!!selection} duration={150} exitDuration={150} timingFunction="linear">
        {(transitionStyles) => (
          <Box style={{ ...transitionStyles, transform: `${transitionStyles.transform} translateX(-50%)` }}>
            <Center component="button" onClick={open} className={classnames.mobile__handler}>
              <MessengerIcon />
            </Center>
          </Box>
        )}
      </Transition>
    </Affix>
  );
}
