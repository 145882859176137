'use client';

import { CloseIcon, EyeOutlineIcon } from '@/icons';
import { useAccessibilityStore } from '@/lib/store';
import { NAVBAR_Z_INDEX } from '@/lib/utils';
import { Box, Button, Divider, Drawer, Space, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { clsx } from 'clsx';
import { useLocale, useTranslations } from 'next-intl';
import FontSize from './FontSize';
import { GoogleTranslate } from './GoogleTranslate';
import Themes from './Themes';
import ZoomInOut from './ZoomInOut';
import classnames from './accessibility.module.css';
import Narrator from './narrator';
import ReadingMode from './reading-mode';

type Props = {
  isMobile?: boolean;
};

export function Accessibility({ isMobile = false }: Props) {
  const t = useTranslations();
  const locale = useLocale();
  const [opened, { open, close }] = useDisclosure(false);
  const { resetAccessibiltyTools, getIsDefaultSettings } = useAccessibilityStore((store) => store);

  return (
    <Box className={clsx(classnames.accessibility, { [classnames.mobile]: isMobile })}>
      <Tooltip
        zIndex={NAVBAR_Z_INDEX + 1}
        label={t('Maxsus imkoniyatlar')}
        events={{ hover: true, focus: true, touch: false }}
        withArrow
        arrowSize={5}
        offset={0}
        maw={120}
        multiline={true}
        color="secondary"
        position="bottom"
        className="text-center leading-tight"
      >
        <button className={classnames.trigger} onClick={open}>
          <EyeOutlineIcon />
        </button>
      </Tooltip>
      <Drawer.Root opened={opened} onClose={close} keepMounted position="right" zIndex={NAVBAR_Z_INDEX + 1}>
        <Drawer.Overlay />
        <Drawer.Content>
          <Drawer.Header>
            <button
              onClick={close}
              className={classnames.drawer__close}
              // aria-label={t('{text}ni yopish', { text: t('Maxsus imkoniyatlar') })}
              aria-label={t('Yopish')}
              data-autofocus
            >
              <CloseIcon />
            </button>
            <Drawer.Title className="w-full text-lg text-center">{t('Maxsus imkoniyatlar')}</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body className="flex flex-col gap-5">
            <Themes />
            <Divider />
            <FontSize />
            <Divider />
            <ZoomInOut />
            {locale !== 'qr' ? (
              <>
                <Divider />
                <Narrator />
              </>
            ) : null}
            <Divider />
            <GoogleTranslate />
            <Divider />
            <ReadingMode />
            <Divider />
            <Space />
            <Button color="gray" size="md" onClick={resetAccessibiltyTools} disabled={getIsDefaultSettings()}>
              {t('Boshlang‘ich holatga qaytarish')}
            </Button>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </Box>
  );
}
