'use client';

import Script from 'next/script';
import { YMInitializer } from 'react-yandex-metrika';

export function Scripts() {
  return (
    <>
      <Script id="www-counter" src="/scripts/www-counter.js" async strategy="lazyOnload" />
      <Script
        id="google-tag-manager"
        src="https://www.googletagmanager.com/gtag/js?id=G-XESZL19D7L"
        async
        strategy="lazyOnload"
      />
      <YMInitializer
        accounts={[96657348]}
        options={{
          defer: true,
          webvisor: true,
          clickMap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          ecommerce: 'dataLayer',
        }}
      />
    </>
  );
}
